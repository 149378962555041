import { Card, CardContent, CardMedia, Typography } from '@mui/material'
import React from 'react'

const CardOffer = ({img, title}) => {
  return (
    <>
          <Card sx={{ maxWidth: 345, my: 1, boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.20)", }}>
              <CardMedia
                  sx={{ height: 140 }}
                  image={img}
                  title="green iguana"
              />
              <CardContent sx={{ textAlign: 'center' }} >
                  <Typography gutterBottom variant="h6" component="div" color={'#FABB25'} sx={{ textTransform: 'uppercase' }} >
                      {title}
                  </Typography>
              </CardContent>
          </Card>
    </>
  )
}

export default CardOffer